.icon-button {
    display: inline-block;
    border: none;
    margin: 0;
    text-decoration: none;
    background: transparent;
    font-family: sans-serif;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out,
                transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.icon-button:hover,
.icon-button:focus {
    transform: scale(1.05);
}

.icon-button:focus {
    box-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    outline-color: #e944c9;
}

.icon-button:active {
    transform: scale(0.99);
}

a:focus {
    box-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    outline-color: #e944c9;
}
